/**
 * About - md
 */

// Left content
.about-left {
	width: 390px;
	margin: 15px 0 0 20px;
}

// Right content
.about-right {
	width: 490px;
	margin: 15px 20px 0;
}

.about-table {
	th, td {
		display: table-cell;
	}
	th { padding: 0 14px 0 0; }
}

// Licence Table
