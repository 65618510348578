/**
 * Treatment - md
 */

.treatment-wrap,
.treatment-wrap-2 {
	padding: 40px 40px 20px;
}

.treatment-wrap-2 {
	> .txt {
		width: calc(50% - 12px);
	}
	> .pic {
		width: calc(50% - 13px);
		margin: 0 0 0 25px;
		> picture > img { width: 100%; }
	}
}
