/**
 * Top - md
 */

// Left content
section.top-left {
	width: 655px;
	margin: 10px 0 0 10px;
	> p {
		margin: 25px 0 0 10px;
	}
}

div.taisho {
	margin: 20px 0 0;
	> figure { width: 154px; }
	> .text {
		width: 206px;
		margin: 0 0 0 138px;
		padding: 10px 15px;
	}
}

figure.taisho {
	margin: 20px 0 0;
}

// Right content
section.top-right {
	width: 240px;
	margin: 10px 10px 0 20px;
}

section.service-hour {
	margin: 0 -5px;
}

section.night-icu {
	> p:last-child {
		margin: 5px -5px 0;
	}
}