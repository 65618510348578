/**
 * Trimming - md
 */

main.trimming {
	h1 {
		width: 100%;
		margin: 15px 0 0 20px; 
	}
}

// Left content
.trimming-left {
	width: 425px;
	margin: 30px 0 0 20px;
}

// Right content
.trimming-right {
	width: 435px;
	margin: 30px 20px 0 45px;
}

table.trimming {
	width: 100%;
	font-size: 13px;
	th, td {
		font-weight: normal;
		padding: 1px 0;
		&:first-child { width: 210px; }
	}
}

.trimming-option {
	> ul {
		margin: 5px -4px 0 0;
		> li {
			margin: 0;
			&:nth-child(3n+1) { width: 150px; }
			&:nth-child(3n+2) { width: 153px; }
			&:nth-child(3n) { width: 102px; }
		}
	}
}