/**
 * Common CSS - md
 */

// Wrapper
#wrapper {
	width: 951px;
	max-width: none;
}

// Header
.header {
	height: 85px;
	background: url(../img/header/head_back.jpg) no-repeat center top;
	justify-content: flex-start;
	padding: 0;
}

.head_address {
	font-size: 13px;
	letter-spacing: 1px;
	width: 300px;
	margin: 1px 0 0 170px;
	order: 2;
}

.mukyu-facebook {
	margin: 0;
	order: 3;
	> p {
		font-size: 16px;
		margin: 0 6px 0 0;
		padding: 6px 7px;
	}
	> figure {
		> a > img { width: 29px; }
	}
}

.head_title {
	// float: left;
	width: 250px;
	margin: 0 0 0 30px;
	padding: 0;
	order: 1;
	> a > img { width: 100%; }
}

button.nav-toggle { display: none; }

// Global Navigation
#navibar {
	max-height: none !important;
	> ul {
		flex-wrap: nowrap;
		> li {
			border-top: none;
			border-left: 1px solid #3c312e;
			width: 171px;
			> a {
				width: 100%;
				height: 100%;
				align-items: center;
				background-image: linear-gradient(#5d504e, #866e64);
				box-sizing: border-box;
				display: flex;
				font-size: 15px;
				justify-content: center;
				padding: 9px 0;
				span.bullet { display: none; }
				sup {
					transform: translateY(-4px);
				}
				br { display: block; }
				&:hover { background: #5d504e; }
			}
			&:first-child {
				border-left: none;
				width: 91px;
			}
			&:last-child { border-bottom: none; }
		}
	}
}

// Main
#main {
	padding: 0 0 20px;
}