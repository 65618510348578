/**
 * Staff - md
 */

// Left content
.staff-left {
	width: 465px;
	margin: 15px 0 0 20px;
}

section.aisatu {
	> p:first-child {
		margin: 20px 0 0;
	}
	> p:last-child { margin: 0; text-align: center; }
}

section.staffs {
	position: relative;
	> h2, > h3, > p { max-width: 265px; }
	> figure {
		position: absolute;
		right: 0;
		top: 0;
		margin: 0;	
	}
}

table.staff-schedule {
	th, td {
		border: 2px solid #666;
		padding: 1px 0;
	}
}

// Right content
.staff-right {
	width: 410px;
	margin: 25px 20px 0 30px;
}

.incho-main {
	> picture { width: 110px; }
	> .text {
		width: auto;
		margin: 0 0 0 15px;
		> h2 {
			> small { font-size: 10px; }
		}
	}
}