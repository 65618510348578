/**
 * Innai - md
 */

main.innai { margin: 20px 20px 0; }

// Honin
section.honin { margin: 10px 0 0; }

section.honin {
	> h2 {
		width: 149px;
		float: left;
		margin: 2px 0 0;
	}
}

figure.innai {
	width: 20%;
	display: inline-block;
	// float: none;
	text-align: right;
	&.isFirst { margin-left: 32px; }
	> img {
		width: 162px;
	}
}

// Careroom
section.careroom {
	position: relative;
	margin: 40px 0 0;
	> figure.innai-mado {
		width: 149px;
		margin: 110px 0 0;
	}
}

a.to-devices {
	margin: 10px auto 0 170px;
}

.careroom-box {
	position: absolute;
	bottom: 0;
	right: 0;
	width: calc(100% - 238px);
	padding: 15px 15px 81px;
	> figure {
		position: absolute;
		width: 400px;
		right: 20px;
		bottom: 5px;
		text-align: right; 
	}
}

// Devices
main.devices {
	> h1 {
		margin: 20px 0 0 20px;
	}
}

.devices-figures {
	margin: 20px 0 0;
	> figure:first-child {
		width: 440px;
		margin: 0 0 0 20px;
	}
	> figure:nth-child(2) {
		width: 440px;
		margin: 0 20px;
	}
	> figure:nth-child(3) {
		width: 901px;
	}
	> figure:last-child {
		width: auto;
	}
}
