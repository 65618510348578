/**
 * Info - md
 */

main.info {
	> h1 {
		width: 100%;
		margin: 15px 0 0 25px;
	}
}

// Left content
.info-left {
	width: 425px;
	margin: 20px 0 0 25px;
}

// Right content
.info-right {
	width: 425px;
	margin: 20px 25px 0 30px;
}

.info-job-contact {
	> .txt {
		font-size: 12px;
		> ul {
			font-size: 14px;
		}
	}
}

.info-notification {
	padding: 16px 16px 0;
	> p {
	}
	> picture {
			margin: 15px 0 10px -16px;
		}
}

.info-trimmer {
	> p {
		font-size: 12px;
	}
}
